export default function AgeLabel(props) {
  const from = props.from;
  const to = props.to;
  let text = `Fra ${from} år`;
  if (from != null && to != null) {
    text = `${from}-${to} år`;
    if (from === 70 && to === 100) {
      text = `${from} år og derover`;
    }
  }
  return text;
}
