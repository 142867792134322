import React, { useState } from "react";
import { Button, Card, Input, Space } from "antd";

const Welcome = () => {
  const [token, setToken] = useState("");

  return (
    <Space
      style={{ display: "flex", marginTop: 150 }}
      align="center"
      direction="vertical"
      size="large"
    >
      <Card>
        <h3>Velkommen Dänischer Jugendverband!</h3>
        <p>Indsæt en kode og klik fortsæt...</p>
        <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
          <Input
            style={{ flex: 1 }}
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
          <div style={{ display: "flex", placeContent: "end" }}>
            <Button type="primary" href={`/?st=${token}`}>
              Blive ved
            </Button>
          </div>
        </div>
      </Card>
    </Space>
  );
};
export default Welcome;
