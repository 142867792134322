import React from "react";
import _ from "lodash";

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    if (!props.storageKey) {
      throw new Error("missing storage key");
    }
    if (!props.storageLocation) {
      throw new Error("missing storage location");
    }
    if (!props.updateStorage) {
      throw new Error("missing update storage function");
    }
    const fetchedValue = this.getValue();
    if (!fetchedValue) {
      _.setWith(
        this.props.storageLocation,
        this.props.storageKey,
        {
          isValid: null,
          value: null,
        },
        Object
      );
      props.updateStorage(this.props.storageLocation);
    }
  }

  setValue(value) {
    _.setWith(
      this.props.storageLocation,
      this.props.storageKey + ".value",
      value,
      Object
    );
    this.props.updateStorage(this.props.storageLocation);
    
    //Activities
    if(this.props.storageKey.includes('kommentar')) {
      localStorage.setItem('activities', JSON.stringify(this.props.storageLocation))
    }
  }

  setValid(valid) {
    _.setWith(
      this.props.storageLocation,
      this.props.storageKey + ".isValid",
      valid,
      Object
    );
    this.props.updateStorage(this.props.storageLocation);
  }

  getValue() {
    return _.get(this.props.storageLocation, this.props.storageKey, null);
  }

  handleChange(e) {
    const rawValue = e.target.value;
    this.setValid(true);
    this.setValue(rawValue);
  }

  render() {
    const valid = _.get(
      this.props.storageLocation,
      this.props.storageKey + ".isValid"
    );
    return (
      <input
        value={this.getValue()?.value ?? ""}
        className={"tableInput"}
        onChange={this.handleChange.bind(this)}
        type="text"
        style={{ color: valid === false ? "red" : "black" }}
      />
    );
  }
}

export default TextInput;
