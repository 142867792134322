import React from "react";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      handlePlus: props.handlePlus,
      showPlusButton: props.showPlusButton,
    };
  }
  render() {
    let button = null;

    if (this.state.showPlusButton) {
      button = (
        <button
          className={"btn btn-sm btn-success no-print"}
          onClick={this.state.handlePlus}
          data-html2canvas-ignore
        >
          {" "}
          <i className={"fa fa-plus"} />
        </button>
      );
    }

    return (
      <div style={{ marginTop: "15px" }} className="card w-100">
        <div className="card-header">
          {this.state.title} {button}
        </div>
        {this.props.children}
      </div>
    );
  }
}
