import React from "react";
import { Modal } from "react-bootstrap";
import ChoseTrainer from "./utils/ChoseTrainer";
import SelectSearch from "react-select-search";
import fuzzySearch from "react-select-search/dist/esm/fuzzySearch";

export default class Person extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person: props.person,
      edit: false,
      groups: props.groups,
      activities: props.activities,
      departments: props.departments,
      selectedDepartments: [],
      board: props.board,
      showDepartments: false,
      showBoard: false,
    };
    if (!this.state.person) {
      throw new Error("missing person in 'Person' class");
    }
    if (!this.state.groups) {
      throw new Error("missing groups in 'Person' class");
    }
    if (!this.state.activities) {
      throw new Error("missing activities in 'Person' class");
    }
  }

  hideModalHandler() {
    this.setState({
      edit: false,
    });
  }

  showModal() {
    this.setState({
      edit: true,
    });
  }

  handleInputChange(key, newValue) {
    const person = this.state.person;
    person[key] = newValue;
    this.props.updatePerson(person);
  }

  inputField(key, type, label, required = false) {
    if (!this.state.person[key]) {
      this.state.person[key] = "";
    }

    let asterix = "";
    if (required) {
      asterix = <span style={{ color: "red" }}> * </span>;
    }

    return (
      <div className={"form-group row"}>
        <label className="col-sm-4 col-form-label">
          {label} {asterix}
        </label>
        <div className={"col-sm-8"}>
          <input
            className={"form-control"}
            type={type}
            onChange={(e) => this.handleInputChange(key, e.target.value)}
            value={this.state.person[key]}
          />
        </div>
      </div>
    );
  }

  handleDelete() {
    const confirmed = window.confirm("Vil du virkelig slette denne person?");
    if (confirmed) {
      this.props.deletePerson(this.state.person.id);
    }
  }

  handleBoardChange(board) {
    const p = this.state.person;
    p.board = board;
    this.props.updatePerson(p);
    if (!this.isHeadOfDepartment()) {
      p.departments = [];
      this.props.updatePerson(p);
    }
  }

  isHeadOfDepartment() {
    if (!this.state.person.board) {
      return false;
    }
    const headOfDepartment = 8;
    return !!this.state.person.board.find((b) => b === headOfDepartment);
  }

  handleDepartmentChange(departments) {
    const p = this.state.person;
    p.departments = departments;
    this.props.updatePerson(p);
  }

  handleShowDepartments(e) {
    this.setState(
      {
        showDepartments: !this.state.showDepartments,
      },
      () => {
        if (this.state.showDepartments === false) {
          e.target.blur();
        }
      }
    );
  }

  handleBlurDepartment() {
    this.setState({
      showDepartments: false,
    });
  }

  handleShowBoard(e) {
    this.setState(
      {
        showBoard: !this.state.showBoard,
      },
      () => {
        if (this.state.showBoard === false) {
          e.target.blur();
        }
      }
    );
  }

  handleBoardBlur() {
    this.setState({
      showBoard: false,
    });
  }

  render() {
    return (
      <>
        <tr>
          <td>{this.state.person.firstName}</td>
          <td>{this.state.person.lastName}</td>
          <td>{this.state.person.street}</td>
          <td>{this.state.person.plz}</td>
          <td>{this.state.person.city}</td>
          <td>{this.state.person.email}</td>
          <td>{this.state.person.phoneNumber}</td>
          <td>{this.state.person.mobileNumber}</td>
          <td className="no-print" data-html2canvas-ignore>
            <button
              onClick={this.showModal.bind(this)}
              className={"btn btn-sm btn-primary"}
            >
              <span className="fa fa-pencil" />
            </button>
            <button
              onClick={this.handleDelete.bind(this)}
              className={"btn btn-sm btn-danger"}
            >
              <span className="fa fa-trash" />
            </button>
          </td>
        </tr>
        <Modal
          size={"lg"}
          show={this.state.edit}
          onHide={this.hideModalHandler.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Rediger person</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"row"}>
              <div className={"col-sm-6"}>
                <div className={"form-horizontal"}>
                  {this.inputField("firstName", "text", "Fornavn", true)}
                  {this.inputField("lastName", "text", "Efternavn", true)}
                  {this.inputField("street", "text", "Gade")}
                  {this.inputField("plz", "number", "Postnummer")}
                  <div className={"form-group row"}>
                    <label className="col-sm-4 col-form-label">
                      Bestyrelse
                    </label>
                    <div className={"col-sm-8"}>
                      <SelectSearch
                        onBlur={this.handleBoardBlur.bind(this)}
                        printOptions={this.state.showBoard ? "always" : "never"}
                        closeOnSelect={false}
                        onChange={this.handleBoardChange.bind(this)}
                        filterOptions={fuzzySearch}
                        search
                        multiple
                        className={"select-search-custom"}
                        options={this.state.board}
                        value={this.state.person.board}
                        renderValue={(valueProps) => (
                          <input
                            className={"select-search-custom__input"}
                            {...valueProps}
                            onClick={this.handleShowBoard.bind(this)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className={"form-horizontal"}>
                  {this.inputField("city", "text", "By")}
                  {this.inputField("email", "email", "E-mail", true)}
                  {this.inputField("phoneNumber", "text", "Tlf")}
                  {this.inputField("mobileNumber", "text", "Mobilnr.")}
                  {(() => {
                    if (this.isHeadOfDepartment()) {
                      return (
                        <div className={"form-group row"}>
                          <label className="col-sm-4 col-form-label">
                            Afdelinger
                          </label>
                          <div className={"col-sm-8"}>
                            <SelectSearch
                              onBlur={this.handleBlurDepartment.bind(this)}
                              printOptions={
                                this.state.showDepartments ? "always" : "never"
                              }
                              closeOnSelect={false}
                              onChange={this.handleDepartmentChange.bind(this)}
                              filterOptions={fuzzySearch}
                              search
                              multiple
                              className={"select-search-custom"}
                              options={this.state.departments}
                              value={this.state.person.departments}
                              renderValue={(valueProps) => (
                                <input
                                  className={"select-search-custom__input"}
                                  {...valueProps}
                                  onClick={this.handleShowDepartments.bind(
                                    this
                                  )}
                                />
                              )}
                            />
                          </div>
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>
            <hr />
            <div
              style={{ height: 250, overflowY: "scroll", overflowX: "hidden" }}
            >
              <ChoseTrainer
                updatePerson={this.props.updatePerson}
                activities={this.state.activities}
                label={"Træner/instruktør/leder for børn"}
                storageLocation={"trainer_trainer_children"}
                person={this.state.person}
              />
              <ChoseTrainer
                updatePerson={this.props.updatePerson}
                activities={this.state.activities}
                label={"Træner/instruktør/leder for ungdommen"}
                storageLocation={"trainer_trainer_teenager"}
                person={this.state.person}
              />
              <ChoseTrainer
                updatePerson={this.props.updatePerson}
                activities={this.state.activities}
                label={"Træner/instruktør/leder for voksne"}
                storageLocation={"trainer_trainer_adults"}
                person={this.state.person}
              />
              <ChoseTrainer
                updatePerson={this.props.updatePerson}
                activities={this.state.activities}
                label={"Træner/instruktør/leder for seniorer"}
                storageLocation={"trainer_trainer_seniors"}
                person={this.state.person}
              />

              <ChoseTrainer
                updatePerson={this.props.updatePerson}
                activities={this.state.activities}
                label={"Hjælpetræner/instruktør/leder for børn"}
                storageLocation={"trainer_supportTrainer_children"}
                person={this.state.person}
              />
              <ChoseTrainer
                updatePerson={this.props.updatePerson}
                activities={this.state.activities}
                label={"Hjælpetræner/instruktør/leder for ungdommen"}
                storageLocation={"trainer_supportTrainer_teenager"}
                person={this.state.person}
              />
              <ChoseTrainer
                updatePerson={this.props.updatePerson}
                activities={this.state.activities}
                label={"Hjælpetræner/instruktør/leder for voksne"}
                storageLocation={"trainer_supportTrainer_adults"}
                person={this.state.person}
              />
              <ChoseTrainer
                updatePerson={this.props.updatePerson}
                activities={this.state.activities}
                label={"Hjælpetræner/instruktør/leder for seniorer"}
                storageLocation={"trainer_supportTrainer_seniors"}
                person={this.state.person}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.hideModalHandler.bind(this)}
              className={"btn btn-sm btn-primary"}
            >
              gem og luk
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
