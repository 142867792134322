import React from "react";
import Member from "./Member";
import Activities from "./Activities";
import axios from "axios";
import * as _ from "lodash";
import People from "./People";
import calculatePersonChangeEvents from "./utils/calculatePersonChangeEvents";
import { Modal, Button } from "react-bootstrap";
import FormFilledBy from "./FormFilledBy";
import { Modal as AntModal, FloatButton, Popconfirm } from "antd";
import { PrinterOutlined, DownloadOutlined } from "@ant-design/icons";
import html2pdf from "html2pdf.js";

export default class ClubDataUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activityStorage: {},
      memberCountStorage: {},
      departments: props.departments,
      board: props.board,
      clubIdInContextOfYearlyReport: props.clubIdInContextOfYearlyReport,
      originalPeople: props.originalPeople,
      showSubmitErrorModal: false,
      warningModalWarnings: [],
      confirmScreen: false,
      clubName: props.clubName,
    };
    this.updateMemberCountStorage = this.updateMemberCountStorage.bind(this);
    this.updateActivityStorage = this.updateActivityStorage.bind(this);
    this.submitData = this.submitData.bind(this);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    //Members
    if (localStorage.getItem("members")) {
      const members = JSON.parse(localStorage.getItem("members"));

      this.setState({
        memberCountStorage: members,
      });
    }

    //Activities
    if (localStorage.getItem("activities")) {
      const activities = JSON.parse(localStorage.getItem("activities"));

      this.setState({
        activityStorage: activities,
      });
    }

    //People
    if (localStorage.getItem("people")) {
      const people = JSON.parse(localStorage.getItem("people"));
      this.props.updatePeople(people);
    }
  }

  updateActivityStorage(as) {
    this.setState({
      activityStorage: as,
    });
  }

  updateMemberCountStorage(mcs) {
    this.setState({
      memberCountStorage: mcs,
    });
  }

  showWarningModal(payload) {
    this.setState({
      showSubmitErrorModal: true,
      payload: payload,
    });
  }

  hideWarningModal() {
    this.setState({
      showSubmitErrorModal: false,
    });
  }
  alert(status) {
    status === "success" &&
      AntModal.success({
        title: "Succes!",
        content: (
          <>
            <p>
              Din indberetning er nu sendt og dataene modtaget. Hvis der er
              spørgsmål mht. indberetningen, kontakter vi dig.
            </p>
            <p>
              SdU-hilsner <br />
              afdeling Idræt, Unge og Kultur{" "}
            </p>
          </>
        ),
        onOk: () => {
          this.setState({ confirmScreen: true });
        },
      });
    status === "error" &&
      AntModal.error({
        title: "Fejl!",
        content: "Der opstod en fejl, data blev IKKE overført!",
      });
  }

  sendRequest(payload) {
    axios
      .post(
        `https://hook.eu1.make.com/ybc2jh9rscsqj9tghtjuc2kjfwn7t7vb`,
        payload
      )
      .then(() => {
        this.alert("success");
      })
      .catch((e) => {
        console.error(e);
        this.alert("error");
      });
  }

  submitData() {
    const form = this.formRef.current;

    if (!form.reportValidity()) {
      alert(
        "Formularen er ikke fuldstændig udfyldt; udfyld venligst alle de tomme celler, som er fremhævet med rød baggrund."
      );
      return;
    }

    const events = calculatePersonChangeEvents(
      this.state.originalPeople,
      this.props.people
    ).map((e) => {
      return {
        personID: e.personID,
        eventType: e.eventType,
      };
    });

    const mcs = this.state.memberCountStorage;
    const as = this.state.activityStorage;

    let valid = true;

    const setValid = (isValid) => {
      if (isValid !== true) {
        valid = false;
      }
    };

    const members = this.props.years.map((year) => {
      const yearID = year.yearId;
      if (!yearID) {
        throw new Error("missing year id");
      }

      const countOfMembers = mcs.countOfMembers[yearID];
      const danishBelow25 = mcs.danishBelow25[yearID];
      const passive = mcs.passive[yearID];

      setValid(countOfMembers.m.isValid);
      setValid(countOfMembers.w.isValid);
      setValid(passive.m.isValid);
      setValid(passive.w.isValid);
      const data = {
        yearID: yearID,
        countOfMembersM: countOfMembers.m.value,
        countOfMembersW: countOfMembers.w.value,
        passiveM: passive.m.value,
        passiveW: passive.w.value,
      };

      if (danishBelow25) {
        setValid(danishBelow25.m.isValid);
        setValid(danishBelow25.w.isValid);
        data["danishBelow25M"] = danishBelow25.m.value;
        data["danishBelow25W"] = danishBelow25.w.value;
      }

      return data;
    });

    const activities = [];
    const activityGroupMetadata = [];
    _.forEach(as, (groups, activity) => {
      _.forEach(groups, (group, groupNumber) => {
        const gn = groupNumber + 1;
        setValid(group.danishBelow25M.isValid);
        setValid(group.danishBelow25W.isValid);
        setValid(group.trainerM.isValid);
        setValid(group.trainerW.isValid);
        setValid(group.countActivityMeetings.isValid);
        activityGroupMetadata.push({
          activity: activity,
          groupNumber: gn,
          danishBelow25M: group.danishBelow25M.value,
          danishBelow25F: group.danishBelow25W.value,
          trainerM: group.trainerM.value,
          trainerF: group.trainerW.value,
          countActivityMeetings: group.countActivityMeetings.value,
          kommentar: group.kommentar.value,
        });
        _.forEach(group.years, (year, yearId) => {
          setValid(year.m.isValid);
          setValid(year.w.isValid);
          activities.push({
            activity: activity,
            yearId: yearId,
            groupNumber: gn,
            male: year.m.value,
            female: year.w.value,
          });
        });
      });
    });

    if (!valid) {
      alert("Udfyld alle felter");
      return;
    }

    // people validation
    const failedValidationPeople = this.props.people
      .map((person) => {
        const isNull = (e) => {
          return e === 0 || e === "" || e === null || e === undefined;
        };
        if (
          isNull(person.firstName) ||
          isNull(person.lastName) ||
          isNull(person.email)
        ) {
          return person.firstName + " " + person.lastName;
        }
        return null;
      })
      .filter((p) => p !== null);

    if (failedValidationPeople.length !== 0) {
      alert(
        `Følgende personer mangler data: ${failedValidationPeople.join(", ")}`
      );
      return;
    }

    const personTrainerKeys = [
      "trainer_trainer_children",
      "trainer_trainer_teenager",
      "trainer_trainer_adults",
      "trainer_trainer_seniors",
      "trainer_supportTrainer_children",
      "trainer_supportTrainer_teenager",
      "trainer_supportTrainer_adults",
      "trainer_supportTrainer_seniors",
    ];

    // verify that every activity (context of groups) has a trainer
    const warningModalWarnings = Object.keys(as)
      // filtering out all empty groups
      .filter((activityID) => {
        return as[activityID].length !== 0;
      })
      .filter((activityID) => {
        for (const person of this.props.people) {
          for (const ptk of personTrainerKeys) {
            if (!person[ptk]) {
              person[ptk] = [];
            }
            if (person[ptk].find((a) => a == activityID)) {
              return false;
            }
          }
        }
        return true;
      })
      .map((activityID) => {
        const activity = this.props.activities.find((a) => a.id == activityID);
        if (!activity) {
          throw new Error("couldn't find activitiy");
        }
        return `Der er ingen træner til aktiviteten "${activity.name}" - ret dette, hvis der er en.`;
      });
    this.setState({
      warningModalWarnings: warningModalWarnings,
    });

    const savedContact = localStorage.getItem(this.props.submitToken);
    const parsedContact = savedContact && JSON.parse(savedContact);

    const payload = {
      submit_token: this.props.submitToken,
      activities: activities,
      members: members,
      activityGroupMetadata: activityGroupMetadata,
      people: this.props.people,
      clubIdInContextOfYearlyReport: this.state.clubIdInContextOfYearlyReport,
      events: events,
      Navn: parsedContact.name,
      "Tlf.nr.": parsedContact.phone,
      "E-mail": parsedContact.email,
    };

    if (warningModalWarnings.length !== 0) {
      this.showWarningModal(payload);
      return;
    }

    this.sendRequest(payload);
  }

  handleDownloadPdf(name) {
    const opt = {
      margin: 0,
      filename: `Medlemsstatistik - ${name}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "px",
        format: [1200, 1600],
        orientation: "landscape",
      },
    };
    let content = document.getElementById("root");
    html2pdf().from(content).set(opt).save();
  }

  render() {
    if (this.state.confirmScreen) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: 70,
          }}
        >
          Data saved correctly, you can close the window <br />
          Data gemt korrekt, du kan lukke vinduet
        </div>
      );
    }

    return (
      <React.Fragment>
        <Modal
          size={"lg"}
          show={this.state.showSubmitErrorModal}
          onHide={this.hideWarningModal.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bemærk</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>
                Læs venligst følgende information og ret aktiviteterne eller
                personerne, hvis du har glemt noget
              </b>
            </p>
            <ul>
              {this.state.warningModalWarnings.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.sendRequest(this.state.payload);
                this.hideWarningModal();
              }}
            >
              Send data alligevel
            </Button>
            <Button
              variant="primary"
              onClick={this.hideWarningModal.bind(this)}
            >
              Annuller afsendelse
            </Button>
          </Modal.Footer>
        </Modal>
        <div className={"row"} style={{ marginTop: "30px" }}>
          <div className={"col-sm-12"}>
            {/*<div className="alert alert-warning" role="alert">*/}
            {/*  Alle felter skal udfyldes i blok 1 og 2. Tomme felter udfyldes med*/}
            {/*  0*/}
            {/*</div>*/}
          </div>
        </div>
        <form ref={this.formRef} onSubmit={(e) => e.preventDefault()}>
          <h1>{this.state.clubName}</h1>
          <FormFilledBy />
          <Member
            years={this.props.years}
            memberCountStorage={this.state.memberCountStorage}
            updateMemberCountStorage={this.updateMemberCountStorage}
          />
          <Activities
            updateActivityStorage={this.updateActivityStorage}
            activities={this.props.activities}
            activityStorage={this.state.activityStorage}
            years={this.props.years}
          />
          <People
            board={this.state.board}
            people={this.props.people}
            activities={this.props.activities}
            updatePeople={this.props.updatePeople}
            activityStorage={this.state.activityStorage}
            departments={this.state.departments}
          />
          <hr />
          <Popconfirm
            placement="topLeft"
            title="Er du sikker på, at de indtastede data er korrekt og fuldstændig?"
            onConfirm={this.submitData.bind(this)}
            okText="Ja"
            cancelText="Nej"
          >
            <button
              className="btn btn-primary mb-5 no-print"
              data-html2canvas-ignore
            >
              <span
                className="glyphicon glyphicon-floppy-disk"
                aria-hidden="true"
              />
              Indsend årsindberetningen
            </button>
          </Popconfirm>
        </form>
        <FloatButton.Group className="no-print" data-html2canvas-ignore>
          <FloatButton
            icon={<PrinterOutlined />}
            type="primary"
            tooltip="Print"
            onClick={() => window.print()}
          />
          <FloatButton
            icon={<DownloadOutlined />}
            type="default"
            tooltip="Download"
            onClick={() => this.handleDownloadPdf(this.state.clubName)}
          />
        </FloatButton.Group>
      </React.Fragment>
    );
  }
}
