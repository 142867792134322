import React from "react";
import _ from "lodash";

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    if (!props.storageKey) {
      throw new Error("missing storage key");
    }
    if (!props.storageLocation) {
      throw new Error("missing storage location");
    }
    if (!props.updateStorage) {
      throw new Error("missing update storage function");
    }
    const fetchedValue = this.getValue();
    if (!fetchedValue) {
      _.setWith(
        this.props.storageLocation,
        this.props.storageKey,
        {
          isValid: true,
          value: 0,
        },
        Object
      );
      props.updateStorage(this.props.storageLocation);
    }
  }

  setValue(value) {
    _.setWith(
      this.props.storageLocation,
      this.props.storageKey + ".value",
      value,
      Object
    );
    this.props.updateStorage(this.props.storageLocation);

    //Members
    if (
      this.props.storageKey.includes("countOfMembers") ||
      this.props.storageKey.includes("danishBelow25.") ||
      this.props.storageKey.includes("passive")
    ) {
      localStorage.setItem(
        "members",
        JSON.stringify(this.props.storageLocation)
      );
    }

    //Activities
    if (
      this.props.storageKey.includes("years") ||
      this.props.storageKey.includes(".danishBelow25") ||
      this.props.storageKey.includes("trainer") ||
      this.props.storageKey.includes("countActivityMeetings")
    ) {
      localStorage.setItem(
        "activities",
        JSON.stringify(this.props.storageLocation)
      );
    }
  }

  setValid(valid) {
    _.setWith(
      this.props.storageLocation,
      this.props.storageKey + ".isValid",
      valid,
      Object
    );
    this.props.updateStorage(this.props.storageLocation);
  }

  getValue() {
    return _.get(this.props.storageLocation, this.props.storageKey, null);
  }

  handleChange(e) {
    const rawValue = e.target.value;
    const value = parseInt(rawValue, 10);
    if (value < 0 || value - Math.floor(value) !== 0) {
      this.setValid(false);
    } else {
      this.setValid(true);
    }
    this.setValue(rawValue);
  }

  render() {
    const valid = _.get(
      this.props.storageLocation,
      this.props.storageKey + ".isValid"
    );
    return (
      <input
        value={this.getValue().value ?? 0}
        className={"tableInput"}
        onChange={this.handleChange.bind(this)}
        required={true}
        type="number"
        style={{
          color: valid === false ? "red" : "black",
        }}
      />
    );
  }
}

export default NumberInput;
