export function TD(props) {
  return <td style={{ minWidth: "80px" }}>{props.children}</td>;
}

export function TDColSpan(props) {
  return (
    <td style={{ minWidth: "80px" }} colSpan={2} {...props}>
      {props.children}
    </td>
  );
}

export function TDCol(props) {
  return (
    <td style={{ minWidth: "40px" }} scope="col">
      {props.children}
    </td>
  );
}

export function TDComplete(props) {
  return (
    <td style={{ minWidth: "80px" }} colSpan={22}>
      {props.children}
    </td>
  );
}
