import React from "react";
import Person from "./Person";
import Panel from "./utils/Panel";
import { v4 } from "uuid";

export default class People extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: props.activities,
      selectedActivity: null,
      groups: props.activityStorage,
      departments: props.departments,
      board: props.board,
    };
  }

  deletePerson(id) {
    const people = this.props.people.filter((p) => p.id !== id);
    this.props.updatePeople(people);

    localStorage.setItem("people", JSON.stringify(people));
  }

  handlePlus() {
    const people = this.props.people;
    const id = v4();
    people.push({
      activities: [],
      id: id,
    });
    this.props.updatePeople(people);

    localStorage.setItem("people", JSON.stringify(this.props.people));
  }

  updatePerson(person) {
    let people = this.props.people;
    people = people.filter((p) => p.id !== person.id);
    people.push(person);
    this.props.updatePeople(people);

    localStorage.setItem("people", JSON.stringify(this.props.people));
  }

  render() {
    const rows = this.props.people.map((person) => {
      return (
        <Person
          board={this.state.board}
          key={person.id}
          deletePerson={this.deletePerson.bind(this)}
          person={person}
          updatePerson={this.updatePerson.bind(this)}
          groups={this.state.groups}
          activities={this.state.activities}
          departments={this.state.departments}
        />
      );
    });

    return (
      <div>
        <div className={"row"}>
          <div className={"col-sm-12"}>
            <Panel
              showPlusButton
              title={"Personer"}
              handlePlus={this.handlePlus.bind(this)}
            >
              <div className={"row"}>
                <div className={"col-sm-12"}>
                  <div className="panel panel-default">
                    <div className={"table-responsive"}>
                      <table className={"table table-bordered"}>
                        <thead>
                          <tr>
                            <th>Fornavn</th>
                            <th>Efternavn</th>
                            <th>Gade</th>
                            <th>Postnummer</th>
                            <th>By</th>
                            <th>E-mail</th>
                            <th>Telefonnummer</th>
                            <th>Mobilnummer</th>
                            <th className="no-print" data-html2canvas-ignore>
                              Handlinger
                            </th>
                          </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}
